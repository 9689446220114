@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=PT+Serif:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* .sectionSize {
    @apply lg:px-48 md:px-12 px-4 py-14 flex flex-col items-center;
  } */
  .secondaryTitle {
    @apply font-pt-serif text-3xl bg-left-bottom bg-no-repeat pb-8 px-16 mb-8;
  }
}

:root {
  --foreground-rgb: 0, 0, 93;
  --background-start-rgb: 244, 242, 237;
  --background-end-rgb: 244, 242, 237;
}

body {
  color: rgb(var(--foreground-rgb));
  background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb));
  font-family: "PT Serif", "serif";
}

.sectionSize {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

@media (min-width: 768px) {
  .sectionSize {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (min-width: 1024px) {
  .sectionSize {
    padding-left: 12rem;
    padding-right: 12rem;
  }
}

.secondaryTitle {
  background-position: left bottom;
  background-repeat: no-repeat;
  font-family: PT Serif, serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 242, 237, var(--tw-bg-opacity));
}

.error {
  background-color: rgba(199, 62, 29, var(--tw-bg-opacity));
}

.markdownHolder {
  font-size: 16px;
}

.markdownHolder p {
  @apply mb-[8px];
}

/* if p is inside li */
.markdownHolder li p {
  @apply mb-[0px];
}

.markdownHolder ol {
  @apply list-inside list-decimal;
}

.markdownHolder ul {
  @apply list-inside list-disc;
}

.markdownHolder h1 {
  @apply text-xl font-bold mb-[8px];
}

.markdownHolder h2 {
  @apply text-lg font-bold mb-[8px];
}

.markdownHolder h3 {
  @apply text-base font-bold mb-[8px];
}

.markdownHolder h4,
.markdownHolder h6,
.markdownHolder h6 {
  @apply text-base font-bold mb-[8px];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
pre {
  margin: 0.5em 0;
  overflow: auto;
  border-radius: 0.3em;
  color: #fff;
  font-size: 13px !important;
}

:not(pre) > code {
  background-color: #222a39 !important;
  color: #fff !important;
  padding: 2px !important;
  border-radius: 2px !important;
}
.markdownHolder {
  font-size: 16px !important;
  padding-top: 4px !important;
}
.markdownHolder p {
  margin: 0px;
}
@media (min-width: 768px) {
  .inset-0 {
    inset: 0px !important;
  }
}
ul {
  padding: 0px;
  list-style-type: disc;
  list-style-position: inside;
}

ol {
  padding: 0px;
  list-style: decimal;
  list-style-position: inside;
}
blockquote {
  margin: 0px;
  padding: 4px 0px;
  font-size: 12px;
  font-weight: 500;
}

.growing-underline {
  display: block;
  position: relative;
  padding: 0.2em 0;
  overflow: hidden;
}

.growing-underline::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1em;
  background-color: black;
  transition: opacity 300ms, transform 300ms;
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.growing-underline:hover::after,
.growing-underline:focus::after {
  transform: translate3d(0, 0, 0);
}
